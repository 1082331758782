import { HttpClient, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { isNil } from "lodash-es";

@Injectable()
export class ApiHttpClient extends HttpClient {
  public baseUrl: string;

  public constructor(handler: HttpHandler) {
    super(handler);

    this.baseUrl = environment.apiUrl;
  }

  public get<T>(url: string, options?: object, withCredentials: boolean = true): Observable<T> {
    const [_url, _options] = this.prepare(url, options, withCredentials);

    return super.get<T>(_url, _options);
  }

  public post<T>(url: string, body: any | null, options?: object, withCredentials: boolean = true) {
    const [_url, _options] = this.prepare(url, options, withCredentials);

    return super.post<T>(_url, body, _options);
  }

  public delete<T>(url: string, options?: object, withCredentials: boolean = true) {
    const [_url, _options] = this.prepare(url, options, withCredentials);

    return super.delete<T>(_url, _options);
  }

  public patch<T>(url: string, body: any | null, options?: object, withCredentials: boolean = true) {
    const [_url, _options] = this.prepare(url, options, withCredentials);

    return super.patch<T>(_url, body, _options);
  }

  private prepare(url: string, options: object | undefined, withCredentials: boolean): [string, object] {
    let _options: object = {
      withCredentials
    };

    if (!isNil(options)) {
      _options = {
        ...options,
        withCredentials
      }
    }

    return [this.baseUrl + url, _options];
  }
}
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { orderBy, isEmpty } from "lodash-es";

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  private autocompleteSvc = new google.maps.places.AutocompleteService();
  private geocoderSvc = new google.maps.Geocoder();

  constructor() { }

  findAddress(input: string): Observable<google.maps.places.AutocompleteResponse> {
    return from(this.autocompleteSvc.getPlacePredictions({
      input,
      componentRestrictions: {
        country: 'gb'
      },
      types: ['address']
    }));
  }

  getPlaceDetails(placeId: string): Observable<google.maps.GeocoderResponse> {
    return from(this.geocoderSvc.geocode({ placeId }));
  }

  async getAddress(location: { lat: number; lng: number }): Promise<{ placeId: string; address: string; } | undefined> {
    const response = await this.geocoderSvc.geocode({ location });

    if (isEmpty(response.results)) return undefined;

    const orderedByRelevance = orderBy(response.results, (r) => r.address_components.length, ['desc']);
    return {
      placeId: orderedByRelevance[0].place_id,
      address: orderedByRelevance[0].formatted_address
    };
  }
}
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GoogleMapsModule } from '@angular/google-maps';
import { AuthGuard } from "./auth.guard";
import { ApiHttpClient } from "./http.service";
import { GoogleMapsService } from "./google-maps.service";
import { DateTimePipe } from "./date-time.pipe";

@NgModule({
  imports: [
    HttpClientModule,
    NgxSkeletonLoaderModule,
    GoogleMapsModule
  ],
  declarations: [
    DateTimePipe
  ],
  providers: [
    ApiHttpClient,
    AuthGuard,
    GoogleMapsService
  ],
  exports: [
    NgxSkeletonLoaderModule,
    GoogleMapsModule,
    DateTimePipe
  ]
})
export class CoreModule { }
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { isEmpty, isNil } from 'lodash-es';
import { ApiHttpClient } from 'src/app/core/http.service';
import { AuthUser, SignInRespDto } from './auth.dto';
import { AuthRoutes } from 'src/app/core/http-routes';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private cookieName = 'info';
  private user: AuthUser | undefined;

  constructor(
    private readonly httpClient: ApiHttpClient,
    private readonly cookieSvc: CookieService
  ) { }

  init() {
    if (!isNil(this.user)) {
      this.user = this.loadUser();
    }
  }

  isActivationTokenValid(token: string): Observable<unknown> {
    return this.httpClient.get(AuthRoutes.verifyToken(token));
  }

  signUp(password: string, token: string) {
    return this.httpClient.post(AuthRoutes.signUp, { password, token });
  }

  signIn(credentials: { email: string, password: string }): Observable<unknown> {
    return this.httpClient.post<SignInRespDto>(AuthRoutes.signIn, credentials).pipe(
      switchMap(({ expires, role }: SignInRespDto) => {
        this.user = { role };

        this.saveUser(this.user, new Date(expires));

        return of('');
      })
    );
  }

  signOut(): Observable<unknown> {
    this.httpClient.post(AuthRoutes.signOut, undefined).subscribe();

    this.user = undefined;
    this.removeUser();

    return of('');
  }

  isAuthenticated(): boolean {
    if (isNil(this.loadUser())) {
      return false;
    }

    return true;
  }

  private loadUser(): AuthUser | undefined {
    const content = this.cookieSvc.get(this.cookieName);
    if (isEmpty(content)) return undefined;

    const user: AuthUser = JSON.parse(this.cookieSvc.get(this.cookieName));
    return user;
  }

  private saveUser(user: AuthUser, expiration: Date) {
    this.cookieSvc.set(this.cookieName, JSON.stringify(user), {
      path: '/',
      expires: expiration
    });
  }

  private removeUser() {
    this.cookieSvc.delete(this.cookieName, '/');
  }
}

export const AuthRoutes = {
  signIn: '/admin/auth',
  signOut: '/auth/logout',
  verifyToken: (token: string) => `/auth/admin/sign-up/${token}`,
  signUp: '/auth/admin/sign-up'
};

export const ServiceTypeRoutes = {
  all: '/service-type',
  getById: (id: number) => `/service-type/${id}`,
  add: '/service-type',
  edit: (id: number) => `/service-type/${id}`
};

export const OrgClinicRoutes = {
  all: (page: number, limit: number) => `/clinic?page=${page}&limit=${limit}`,
  getById: (id: string) => `/clinic/${id}`,
  add: '/clinic',
  edit: (id: string) => `/clinic/${id}`
};

export const UserRoutes = {
  getAdmins: (page: number, limit: number) => `/user/admins?page=${page}&limit=${limit}`,
  addAdmin: '/user/admin',
  getOrgAdmins: (params: { orgId?: string | undefined, page: number, limit: number }) => {
    const { orgId, page, limit } = params;
    let url = `/user/org-admins?page=${page}&limit=${limit}`;
    if (orgId) url = `${url}/orgId=${orgId}`

    return url;
  },
  addOrgAdmin: '/user/org-admin'
};
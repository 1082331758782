import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'datetime' })
export class DateTimePipe implements PipeTransform {
  transform(value: DateTime | undefined): string {
    if (!value) return '';

    return value.toFormat('D');
  }
}